import { Grid, Zoom } from '@mui/material';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import {
  useGetAllFieldsQuery,
  useGetAllGeneralsQuery,
  useNewGeneralSetupMutation,
  useUpdateGeneralSetupMutation,
} from 'src/store/api/setups/generalSetupApi';
import {
  MyDatePicker,
  MyFixSelectField,
  MyStatusSelectField,
  MyTextField,
} from 'src/views/components/fields';
import * as util from 'src/utils/common';
import SetupTemplate, { SetupTemplateContext } from 'src/views/components/setup';
import { getSetups } from 'src/store/apps/setup/SetupSlice';
import { useDispatch, useSelector } from 'src/store/Store';

const GeneralSetupInputSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string().max(50).required('The title field is required'),
});

export type NewGeneralSetupInput = Yup.InferType<typeof GeneralSetupInputSchema>;

const SetupGeneralPage = () => {
  const { ep, id } = useParams();

  const dispatch = useDispatch();

  const isAddMode = useSelector((state) => !state.setupReducer.editSetup);

  const [initialValues, setInitialValues] = React.useState({});

  const [submitState, setSubmitState] = React.useState<{
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any;
  }>({ isLoading: false, isSuccess: false, isError: false, error: {} });

  const [newGeneral, { isLoading, isSuccess, error, isError }] = useNewGeneralSetupMutation();

  const [
    updateGeneral,
    {
      isLoading: updateIsLoading,
      isSuccess: updateIsSuccess,
      error: updateError,
      isError: updateIsError,
      data: updateData,
    },
  ] = useUpdateGeneralSetupMutation();

  const {
    data: generals,
    refetch: refetchGeneral,
    isFetching: generalsIsFetching,
    isSuccess: generalsIsSuccess,
  } = useGetAllGeneralsQuery(ep || '');

  const {
    data: fields,
    refetch: refetchFields,
    isFetching: fieldsIsFetching,
    isSuccess: fieldsIsSuccess,
  } = useGetAllFieldsQuery(ep || '');

  useEffect(() => {
    setInitialValues({});

    refetchFields();

    refetchGeneral();
  }, [ep]);

  useEffect(() => {
    setSubmitState({ isLoading, isSuccess, isError, error });
  }, [newGeneral, isLoading, isSuccess, isError]);

  useEffect(() => {
    setSubmitState({
      isLoading: updateIsLoading,
      isSuccess: updateIsSuccess,
      isError: updateIsError,
      error: updateError,
    });
  }, [updateGeneral, isLoading, updateIsSuccess, updateIsError]);

  const submitFormAction = async (values: any) => {
    if (isAddMode) {
      newGeneral({ ep: ep || '', data: values });
    } else {
      // values.id = id;

      updateGeneral({ ep: ep || '', data: values });
    }
  };

  const getInitialValues = () => {
    const result = Object.fromEntries(
      fields?.map((key: string) => [key.toLocaleLowerCase(), '']) || [],
    );

    result['id'] = '0';
    result['isActive'] = '';

    return result;
  };

  React.useEffect(() => {
    if (fieldsIsSuccess) {
      setInitialValues(getInitialValues());
    }
  }, [fieldsIsFetching, fieldsIsSuccess]);

  useEffect(() => {
    if (generalsIsSuccess) {
      dispatch(getSetups(generals));
    }
  }, [generalsIsFetching, generalsIsSuccess]);

  return (
    <>
      {fields && (
        <SetupTemplate
          initialValues={initialValues}
          pageTitle={`Setup - ${util.toPascalCaseWithSpaces(ep || '')}`}
          pageHeaderTitle={`Setup ${util.toPascalCaseWithSpaces(ep || '')}`}
          pageHeaderDescription={`Use this setup to create different types of ${util.toPascalCaseWithSpaces(
            ep || '',
          )} setups`}
          yupSchema={GeneralSetupInputSchema}
          submitAction={submitFormAction}
          submitState={submitState}
          // sidebar={<GeneralSetupSideBar />}
          // sideBarCardTitle=""
          // sideBarDataSource={generals}
          // rowClickNavigateRoute={`/setups/${ep}`}
          sideBarIsFetching={generalsIsFetching}
          // sideBarColumnNames={[
          //   { columnName: 'rowid', width: 80, headerName: 'Sr. No' },
          //   { columnName: 'title', isPrimary: true, headerName: 'TITLE' },
          //   {
          //     columnName: 'createdOn',
          //     headerName: 'DATE CREATED',
          //   },
          //   {
          //     columnName: 'lastModifiedOn',
          //     headerName: 'DATE MODIFIED',
          //   },
          // ]}
        >
          <SetupTemplateContext.Consumer>
            {(formik: any) => (
              <>
                {fields?.map((f, index) => (
                  <Grid item xs={12} key={index}>
                    {f.endsWith('Date') ? (
                      <MyDatePicker
                        formik={formik}
                        name={f.toLocaleLowerCase()}
                        label={util.toPascalCase(f)}
                        placeholder={`${util.toPascalCase(f)} here`}
                      />
                    ) : (
                      <MyTextField
                        formik={formik}
                        name={f.toLocaleLowerCase()}
                        label={util.toPascalCase(f)}
                        placeholder={`${util.toPascalCase(f)} here`}
                      />
                    )}
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <MyStatusSelectField
                    formik={formik}
                    name="isActive"
                    label="Status"
                    placeholder={''}
                  ></MyStatusSelectField>
                </Grid>
              </>
            )}
          </SetupTemplateContext.Consumer>
        </SetupTemplate>
      )}
    </>
  );
};

export default SetupGeneralPage;
