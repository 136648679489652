import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Drawer,
  useMediaQuery,
  Theme,
  CircularProgress,
  Grid,
  Stack,
  Zoom,
  Tabs,
  Divider,
  Tab,
  CardHeader,
  Alert,
  IconButton,
} from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import AppCard from 'src/components/shared/AppCard';
import pageConfig from 'src/config/index.json';
import { useFormik } from 'formik';
import SetupFilter from './SetupFilter';
import SetupList from './SetupList';
import SetupDetails from './SetupDetails';
import SetupSearch from './SetupSearch';
import { useDispatch, useSelector } from 'src/store/Store';
import AppSkeleton from '../skeleton';
import { useSnackbar } from 'notistack';
import { reset } from 'src/store/apps/setup/SetupSlice';
import CloseIcon from '@mui/icons-material/Close';
import { fetchCategories } from 'src/store/apps/lists/ListSlice';
import * as routes from 'src/constants/routes';

const drawerWidth = 240;
const secdrawerWidth = 320;

interface SetupTemplateProps {
  initialValues: {};
  pageTitle: string;
  pageHeaderTitle: string;
  pageHeaderDescription: string;
  yupSchema: any;
  submitAction: (values: any) => Promise<void>;
  submitState: { isSuccess: boolean; isError: boolean; error: any };
  children: React.ReactNode;
  crumb?: any[];
  sideBarCardTitle?: string;
  sideBarIsFetching?: boolean;
  formikValueChanged?: (formik: any, fieldName: string, fieldObject: any) => void;
}

export const SetupTemplateContext = React.createContext({});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SetupTemplate = (props: SetupTemplateProps) => {
  const setupDetail: any = useSelector((state) => state.setupReducer.setupContent);
  const dispatch = useDispatch();

  const [isLeftSidebarOpen, setLeftSidebarOpen] = useState(false);
  const [isRightSidebarOpen, setRightSidebarOpen] = useState(false);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [open, setOpen] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  let BCrumb = [
    {
      to: routes.INDEX,
      title: 'Home',
    },
  ];

  if (props.crumb) {
    BCrumb = BCrumb.concat(props.crumb);
  }

  const formik = useFormik({
    initialValues: props.initialValues,
    validationSchema: props.yupSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
      props.submitAction(values);
    },
  });

  useEffect(() => {
    if (setupDetail) {
      // formik.resetForm();
      // formik.setErrors({});

      Object.keys(formik.initialValues).forEach((field) => {
        if (setupDetail[field] !== null || setupDetail[field] !== undefined) {
          if (field === 'isActive') {
            if (Boolean(setupDetail[field])) {
              formik.setFieldValue("isActive", "true", false);
            } else {
              formik.setFieldValue("isActive", "false", false);
            }
          } else {
            formik.setFieldValue(field, setupDetail[field], false);
          }
        }
        if (props.formikValueChanged) {
          props.formikValueChanged(formik, field, setupDetail);
        }
      });

      setOpen(false);
    }
  }, [setupDetail]);

  useEffect(() => {
    if (props.submitState) {
      if (props.submitState.isSuccess === true) {
        formik.resetForm();
        formik.setStatus({ success: true });
        formik.setSubmitting(false);

        enqueueSnackbar(
          `${props.pageHeaderTitle.replace('Setup', '')} has been saved successfully.`,
          {
            style: { width: '100%' },
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            TransitionComponent: Zoom,
          },
        );
        // if (props.onPopupCancel) {
        //   props.onPopupCancel(false);
        // }
      }
      if (props.submitState.isError) {
        console.log(props.submitState.error);
        formik.setStatus({ success: false });
        formik.setErrors({ submit: props.submitState?.error?.message });
        formik.setSubmitting(false);

        enqueueSnackbar('Error occurred, Pls. contact administrator', {
          style: { width: '100%' },
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          TransitionComponent: Zoom,
        });
      }
    }
  }, [props.submitState]);

  useEffect(() => {
    dispatch(fetchCategories([]));
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <PageContainer title={props.pageTitle} description="this is Setup page">
        <Breadcrumb
          title={props.pageHeaderTitle}
          subtitle={props.pageHeaderDescription}
          items={BCrumb}
        />
        {/* <BlankCard>
          <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
            >
              <Tab
                iconPosition="start"
                icon={<IconEditCircle size="22" />}
                label="Configure"
                {...a11yProps(1)}
              />
              <Tab
                iconPosition="start"
                icon={<IconArticle size="22" />}
                label="View"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
        </BlankCard> */}
        <Divider />
        {/* <TabPanel value={value} index={0}> */}
        <AppCard>
          {/* ------------------------------------------- */}
          {/* Left Part */}
          {/* ------------------------------------------- */}
          {/* <Drawer
            open={isLeftSidebarOpen}
            onClose={() => setLeftSidebarOpen(false)}
            sx={{
              width: drawerWidth,
              [`& .MuiDrawer-paper`]: { width: drawerWidth, position: 'relative', zIndex: 2 },
              flexShrink: 0,
            }}
            variant={lgUp ? 'permanent' : 'temporary'}
          >
            <SetupFilter />
          </Drawer> */}
          {/* ------------------------------------------- */}
          {/* Middle part */}
          {/* ------------------------------------------- */}
          <Box
            sx={{
              minWidth: secdrawerWidth,
              width: { xs: '100%', md: secdrawerWidth, lg: secdrawerWidth },
              flexShrink: 0,
            }}
          >
            <SetupSearch onClick={() => setLeftSidebarOpen(true)} />
            {props.sideBarIsFetching ? (
              <AppSkeleton />
            ) : (
              <SetupList showrightSidebar={() => setRightSidebarOpen(true)} />
            )}
          </Box>
          {/* ------------------------------------------- */}
          {/* Right part */}
          {/* ------------------------------------------- */}
          <Drawer
            anchor="right"
            open={isRightSidebarOpen}
            onClose={() => setRightSidebarOpen(false)}
            variant={mdUp ? 'permanent' : 'temporary'}
            sx={{
              width: mdUp ? secdrawerWidth : '100%',
              zIndex: lgUp ? 0 : 1,
              flex: mdUp ? 'auto' : '',
              [`& .MuiDrawer-paper`]: { width: '100%', position: 'relative' },
            }}
          >
            {/* back btn Part */}
            {mdUp ? (
              ''
            ) : (
              <Box sx={{ p: 3 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => setRightSidebarOpen(false)}
                  sx={{ mb: 3, display: { xs: 'block', md: 'none', lg: 'none' } }}
                >
                  Back{' '}
                </Button>
              </Box>
            )}
            <SetupTemplateContext.Provider value={formik}>
              <SetupDetails setupTitle={props.pageHeaderTitle}>{props.children}</SetupDetails>
              <Grid item xs={12}>
                <Stack direction="column" spacing={1}>
                  {Object.keys(formik.errors)
                    .slice(0, 1)
                    .map(
                      (k: string, i) =>
                        (formik.errors as any)[k]?.length > 0 &&
                        open && (
                          <Alert
                            key={i.toString()}
                            severity="warning"
                            variant="outlined"
                            action={
                              <IconButton onClick={() => setOpen(false)}>
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {(formik.errors as any)[k]}
                          </Alert>
                        ),
                    )}
                </Stack>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid container justifyContent="flex-end">
                <Grid item sx={{ mr: 3 }}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        formik.resetForm();
                        dispatch(reset());
                        // if (props.onPopupCancel) {
                        //   props.onPopupCancel(false);
                        // }
                        // if (props.rowClickNavigateRoute) {
                        //   navigate(props.rowClickNavigateRoute);
                        // }
                      }}
                      color="error"
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      startIcon={formik.isSubmitting ? <CircularProgress size="1rem" /> : null}
                      disabled={formik.isSubmitting}
                      variant="contained"
                      disableElevation
                      color="primary"
                      size="large"
                      onClick={() => setOpen(true)}
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12}></Grid>
            </SetupTemplateContext.Provider>
          </Drawer>
        </AppCard>
        {/* </TabPanel>
        <TabPanel value={value} index={1}>
          <AppCard>
            <></>
          </AppCard>
        </TabPanel> */}
      </PageContainer>
    </form>
  );
};

export default SetupTemplate;
