import React, { useEffect } from 'react';
import { List } from '@mui/material';
import { useSelector, useDispatch } from 'src/store/Store';
import {
  SelectSetup,
  fetchSetups,
  DeleteSetup,
  toggleStarredSetup,
  isEdit,
} from 'src/store/apps/setup/SetupSlice';

import Scrollbar from 'src/components/custom-scroll/Scrollbar';
import SetupListItem from './SetupListItem';
import type { SetupType } from 'src/types/apps/setup';

type SetupListProps = {
  showrightSidebar: () => void;
};

const SetupList = ({ showrightSidebar }: SetupListProps) => {
  const { setupSearch, setups } = useSelector((state) => state.setupReducer);

  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchSetups());
  // }, [dispatch]);

  const active = useSelector((state) => state.setupReducer.setupId);

  return (
    <List>
      <Scrollbar sx={{ height: { lg: 'calc(100vh - 100px)', md: '100vh' }, maxHeight: '800px' }}>
        {setups
          ?.filter(
            (setup: any) =>
              String(setup['title']).includes(setupSearch) ||
              String(setup['description']).includes(setupSearch) ||
              String(setup['name']).includes(setupSearch) ||
              String(setup['exception']).includes(setupSearch) ||
              setupSearch === null ||
              setupSearch === '',
          )
          ?.map((setup: any) => (
            <SetupListItem
              key={setup.id}
              active={setup.id === active}
              {...setup}
              onSetupClick={() => {
                dispatch(SelectSetup(setup));
                showrightSidebar();
              }}
              onDeleteClick={() => dispatch(DeleteSetup(setup.id))}
              onStarredClick={() => dispatch(toggleStarredSetup(setup.id))}
            />
          ))}
      </Scrollbar>
    </List>
  );
};

export default SetupList;
